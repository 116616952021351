<template>
  <div class="reset">
    <el-row :gutter="20" class="reset-wraps">
      <el-col :span="8" class="reset-lefts">
        <img src="@/assets/password.png" alt="">
      </el-col>
      <el-col :span="16" class="reset-rights">
        <h3>偏好设置</h3>
        <p></p>
        <el-form ref="formPhone" :model="form" class="form-content">
          <el-form-item label="阅读方式：">
            <el-radio-group v-model="form.reader_model">
              <el-radio label="0">默认弹窗</el-radio>
              <el-radio label="1">页面打开</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="submit">
            <el-button class="btn-login" type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
       <!-- <div id="main" style="width: 1000px;height:600px;background:#0e1010"></div> -->
    </el-row>
 
  </div>
</template>

<script>
import { Store } from '@/utils/LocalCache.js'
import { userAPI } from 'liankong-ui-api'
import * as echarts from 'echarts'
export default {
  components: {},
  data() {
    return {
      form:{
        reader_model:"0",
      },
		nodedata:[{
			name :"测试",
			value: [50,400],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#000",
					fontSize: 12,//点的标题自豪大小
					position: [10,15]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol: "",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"测试2",
			value: [500,400],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#000",
					fontSize: 12,//点的标题自豪大小
					position: [10,15]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol: "",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"测试3",
			value: [210,300],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#000",
					fontSize: 12,//点的标题自豪大小
					position: [10,15]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol: "",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"测试4",
			value: [800,400],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#000",
					fontSize: 12,//点的标题自豪大小
					position: [10,15]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol: "",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"测试5",
			value: [800,300],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#000",
					fontSize: 12,//点的标题自豪大小
					position: [10,15]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol: "",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
      ],
      linlist:[{
        coords:[
          [50,400],
          [200,300],
        ]
      },{
        coords:[
          [500,400],
          [200,300],
        ]
      },{
        coords:[
          [800,400],
          [500,400],
        ]
      },{
        coords:[
          [800,300],
          [500,400],
        ]
      }]

    };
  },
  computed: {},
  methods: {
    onSubmit(){
      userAPI.setPreference(this.form).then(res=>{
        if(res.code == 0){
          this.$message.success('设置成功！')
          Store.put('myPreference',this.form)
        }else{
          this.$message.error(res.message)
        }
      }).catch((data) => {
        this.$message.error(data.message);
      });
    }
  },
  mounted(){
    if(Store.get('myPreference')){
      this.form = Store.get('myPreference');
    }
    // var myChart = echarts.init(document.getElementById('main'));

	// let option =  {
	// 	title: {
	// 		text: "云监控平台数据交互流",
	// 		top: "top",
	// 		left: "center",
	// 		textStyle:{
	// 			color:"#f8f8f8",
	// 			fontSize:26
	// 		}
	// 	},
	// 	tooltip: {
	// 		trigger: 'item',
	// 		formatter: function(o) {
	// 			if($.isArray(o.value)){
	// 				return o.name + "：" + o.value[2];
	// 			}
	// 			else{
	// 				return o.data.name;
	// 			}
	// 		}
	// 	},
	// 	geo: {
	// 		// 地理系坐标组件
	// 		map: 'MyGum',
	// 		label: {
	// 			emphasis: {
	// 				show: false
	// 			}
	// 		},
	// 		roam: true,
	// 		itemStyle: {
	// 			normal: {
	// 				color:'rgba(255,255,255,0)',
	// 				areaColor:'rgba(255,255,255,0)',
	// 				borderColor:'rgba(22,22,2,0)'

	// 			},
	// 			emphasis: {

	// 				color:'rgba(22,22,2,0)',
	// 				areaColor:'rgba(255,255,255,0)',
	// 				borderColor:'rgba(22,22,2,0)'
	// 			}
	// 		}
	// 	},
	// 	// 点
	// 	series: [{
	// 		type: 'lines',
	// 		zlevel: 1,
	// 		effect: {
	// 			show: true,
	// 			period: 3,
	// 			trailLength: 0.3,
	// 			color: '#A6C84C',
	// 			symbol: "arrow",
	// 			symbolSize: 8
	// 		},
	// 		lineStyle: {
	// 			normal: {
	// 				color: '#a6c84c',
	// 				width: 0,
	// 				curveness: 0.2
	// 			}
	// 		},
	// 		data: this.linlist
	// 	}, {
	// 		// 三角
	// 		type: 'lines',
	// 		zlevel: 2,
	// 		effect: {
	// 			show: true,
	// 			period: 3,
	// 			trailLength: 0,
	// 			symbol: "arrow",
	// 			symbolSize: 15
	// 		},
	// 		lineStyle: {
	// 			normal: {
	// 				color: '#a6c84c',
	// 				width: 1,
	// 				opacity: 0.4,
	// 				curveness: 0.2
	// 			}
	// 		},
	// 		data: this.linlist
	// 	}, {
	// 		// 涟漪
	// 		type: 'effectScatter',
	// 		coordinateSystem: 'geo',
	// 		zlevel: 2,
	// 		rippleEffect: {
	// 			period: 6,
	// 			scale: 2,
	// 			brushType: 'stroke'
	// 		},
	// 		label: {
	// 			normal: {
	// 				show: true,
	// 				position: 'right',
	// 				formatter: '{b}'
	// 			}
	// 		},

	// 		symbolSize: '40',
	// 		itemStyle: {
	// 			normal: {
	// 				color: '#a6c84c',
	// 			}
	// 		},
	// 		data: this.nodedata
	// 	}]
	// }


	// 使用刚指定的配置项和数据显示图表。
	// myChart.setOption(option)
  }
 
};
</script>

<style lang='less' scoped>
.form-content {
  width: 300px;
  padding-bottom: 20px;
}
.box-card {
  border: none;
}
.submit {
  text-align: center;
}

.reset-wraps {
  display: flex;
  margin-top: 88px;
  .reset-lefts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 400px;
      // height: 303px;
    }
  }
  .reset-rights {
    h3 {
      margin: 0px;
      padding: 0px;
      position: relative;
      &::before {
        content: '';
        width: 5px;
        height: 20px;
        background: #409EFF;
        position: absolute;
        left: 0px;
        top: 4px;
      }
      padding-left: 15px;
    }
    p {
      width: 80%;
      color: #737B8B;
      font-size: 14px;
      line-height: 22px;
      margin: 20px 0 30px 0;
    }
    .el-form {
      .el-form-item {
        margin-bottom: 30px;
      }
    }
  }
}
</style>